import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
} from '@mui/material';
import {
    Home as HomeIcon,
    People as PeopleIcon,
    AccessTime as AccessTimeIcon,
    VolunteerActivism as VolunteerActivismIcon
} from '@mui/icons-material';

const DefaultTopBar = ({isScrolling}) => {
    const navigate = useNavigate();

    return (
        <>
            <Box>
                <RouterLink to="/">
                    <img alt={"logo"} src={"/static/images/logoOP.png"} height={90}/>
                </RouterLink>
            </Box>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <Button size="medium" sx={{m: 1, color:"#FFEADF" }} startIcon={<HomeIcon/>} onClick={() => navigate("/app/inicio")}>
                    <Typography color="#FFEADF" variant={"topBar"}>
                        Inicio
                    </Typography>
                </Button>
                <Button size="medium" sx={{m: 1, color:"#FFEADF" }} startIcon={<PeopleIcon/>} onClick={() => navigate("/app/quienessomos")}>
                    <Typography color="#FFEADF" variant={"topBar"}>
                        Quiénes Somos
                    </Typography>
                </Button>
                <Button size="medium" sx={{m: 1, color:"#FFEADF" }} startIcon={<AccessTimeIcon/>} onClick={() => navigate("/app/programacion")}>
                    <Typography color="#FFEADF" variant={"topBar"}>
                        Programación
                    </Typography>
                </Button>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        m: 1,
                        color: "#FF8984",
                        backgroundColor: "#FFEADF",
                        '&:hover': {
                            color: "#ff9590",
                            backgroundColor: "#dfc6be"
                        },
                    }}
                    startIcon={<VolunteerActivismIcon/>}
                    onClick={() => navigate("/app/donaciones")}
                >
                    <Typography variant={"topBar"}>
                        Donaciones
                    </Typography>
                </Button>
            </Box>
        </>
    );
};

DefaultTopBar.propTypes = {
    isScrolling: PropTypes.number
};

export default DefaultTopBar;