import React from 'react';
import {makeStyles} from "@mui/styles";
import {Box, Paper} from "@mui/material";
import BoxMembers from "views/aboutus/AboutUsView/BoxMembers";
import BoxVolunteers from "./BoxVolunteers";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 24,
        marginBottom: 16
    },
    paperImg: {
        width:'100%',
        height: 400,
        paddingBottom: 10,
        backgroundSize: 'cover',
        backgroundAttachment: "fixed",
        [theme.breakpoints.down("md")]: {
            backgroundAttachment: "initial",
            height: 200,
        }
    }
}));

const OndaPazTeam = ({team}) => {
    const classes = useStyles();

    return (
        <Box>
            <Paper
                className={classes.paperImg}
                sx={{
                    backgroundImage: `url(${team.intro.image})`,
                }}
            />
            <BoxMembers title={team.radio.name} members={team.radio.members}/>
            <BoxVolunteers/>
        </Box>
    );
}
export default OndaPazTeam;