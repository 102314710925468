import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Link,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from 'react-router-dom';
import countries from './countries';
import Page from "./Page";
import {EmailService} from "Services/Server/EmailService";
import LoadingScreen from "./LoadingScreen";

const useStyles = makeStyles((theme) => ({
    root: {},
    iconSize: {
        "& .MuiButton-endIcon": {
            '& > *:first-child': {
                fontSize: 60,
                [theme.breakpoints.down("lg")]: {
                    fontSize: 55,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 40,
                },
                [theme.breakpoints.down("sm")]: {
                    fontSize: 15,
                },
            },
        }
    }
}));

const ContactForm = () => {
    const classes = useStyles();
    const [defValue, setDefValue] = useState("");
    const [object] = useState({});
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idForm, setIdForm] = useState(null);
    const [nameForm, setNameForm] = useState(null);
    const [titleForm, setTitleForm] = useState(null);
    const location = useLocation();
    const id = location.state.id;
    const name = location.state.name;
    const title = location.state.title;
    const navigate = useNavigate();

    const sendEmail = async () => {
        const res = await EmailService.PostContactInfo(object);
        if (res) {
            if (res.status === 200) {
                setOpen(true);
                setError(false);
            } else {
                setError(true);
            }
        }
    }

    const handleSend = async (event) => {
        event.preventDefault();
        buildObject('', 'subject', nameForm);
        buildObject('', 'tag', `[${idForm}]`);
        setLoading(true);
        setDefValue("");
        await sendEmail();
        setLoading(false);
    }

    const handleClose = (event) => {
        setOpen(false);
        setError(false);
    }

    const buildObject = (parent, key, value) => {
        if (parent === '') {
            object[key]=value;
        } else {
            if (!object[parent]) {
                let aux = {};
                aux[key] = value;
                object[parent] = aux;
            } else {
                object[parent][key] = value;
            }

        }
    }

    useEffect(() => {
        if (id !== idForm) {
            setIdForm(id);
            window.scrollTo(0, 0);
        }
        if (name !== nameForm) {
            setNameForm(name);
        }
        if (title !== titleForm) {
            setTitleForm(title);
        }
    }, [idForm, nameForm, titleForm, id, name, title])

    return (
        <Page
            className={classes.root}
            title="Contacta con nosotros"
        >
            <Box mx={3} mt={2}>
                <Typography variant={"titles"} color="text.primary" mb={3}> {idForm} </Typography>
            </Box>
            {loading ? <LoadingScreen/>
            :   <form
                    onSubmit={(e) => {
                        handleSend(e);
                    }}
                >
                    <Box px={{xs:3, sm:4, md:6, lg:8}} py={2}>
                        <Card variant="outlined">
                            <CardHeader title={"Contacta con nosotros"}
                                        titleTypographyProps={{variant: "sharedSubTitle", color: "text.primary"}}/>
                            <Divider/>
                            <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Box>
                                    <Typography variant={"sharedBodySmall"} color="text.primary" sx={{textAlign: "justify"}}>
                                        {titleForm}
                                    </Typography>

                                    <TextField fullWidth required={true}
                                               defaultValue={defValue} size={"small"} label="Nombre" id="nombre"
                                               margin="normal"
                                               onChange={(e) => buildObject('message', 'name', e.target.value)}/>
                                    <TextField fullWidth required={true}
                                               defaultValue={defValue} size={"small"} label="Apellidos" id="apellidos"
                                               margin="normal"
                                               onChange={(e) => buildObject('message', 'surname', e.target.value)}/>
                                    <TextField fullWidth required={true}
                                               defaultValue={defValue} size={"small"} label="Correo Electrónico"
                                               id="correo" margin="normal"
                                               onChange={(e) => buildObject('message', 'email', e.target.value)}/>
                                    <TextField fullWidth required={true}
                                               defaultValue={defValue} size={"small"} label="Teléfono de contacto"
                                               id="telefono" margin="normal"
                                               onChange={(e) => buildObject('message', 'phone', e.target.value)}/>

                                    <Autocomplete
                                        id="country-select-demo"
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option.label} ({option.code}) +{option.phone}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                margin="normal"
                                                label="Elige un país"
                                                size={"small"}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        onChange={(e, option) => {
                                            buildObject('message', 'country', option.label)
                                        }}
                                    />
                                    <TextField fullWidth required={true} defaultValue={defValue} label="Descripción"
                                               id="fullWidth" multiline rows={7} margin="normal"
                                               onChange={(e) => buildObject('message', 'description', e.target.value)}/>
                                    <Box mt={2}>
                                        <Typography variant={"sharedBodySmall"} color="text.secondary">
                                            Por favor, revisa nuestra&nbsp;
                                            <Link onClick={() => navigate("/app/advertencialegal")}>Advertencia Legal</Link>, antes de enviar el formulario.
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                        <Box mt={2} sx={{display: 'flex', justifyContent: 'right'}}>
                            <Button
                                className={classes.iconSize}
                                variant="contained"
                                type="submit"
                            >
                                <Typography variant={"coverButton"} color={"white"}>ENVIAR</Typography>
                            </Button>
                        </Box>
                    </Box>
                </form>
            }
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
                    {error ? <> Se ha producido un error </> : <> Gracias por contactarnos! </>}
                </Alert>
            </Snackbar>
        </Page>
    );
}


export default ContactForm;