import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    Box,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Divider,
    Link
} from '@mui/material';
import PropTypes from "prop-types";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import LaunchIcon from '@mui/icons-material/Launch';

import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    boxImage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    }
}));

const BasicTable = ({timetable}) => {
    const classes=useStyles();
    const [openDescription, setOpenDescription] = useState(false);
    const [showProgram, setShowProgram] = useState(0);
    
    const handleClick = (id, description) => {
        setOpenDescription(description);
        setShowProgram(id);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="programTableHeader" color={"text.primary"}>Hora</Typography></TableCell>
                            <TableCell align="left"><Typography variant="programTableHeader" color={"text.primary"}>Programa</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timetable.map((row, index) => (
                            <TableRow
                                key={row.time}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography variant="program" color={"text.primary"}>{row.time}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Box sx={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                                        { row.hasDescription
                                            ?
                                            <Link underline="hover" component="button" onClick={() => handleClick(index, row.description)} sx={{display: "flex", flexDirection: "row"}}>
                                                <Box>
                                                    <Typography color={"inherit"} variant="program">
                                                    {row.program}
                                                    </Typography>
                                                </Box>
                                                <Box ml={1}>
                                                    <LaunchIcon fontSize="medium"/>
                                                </Box>
                                            </Link>
                                            :
                                            <Box>
                                                <Typography color={"text.secondary"} variant="program">
                                                    {row.program}
                                                </Typography>
                                            </Box>
                                        }
                                        {row.isLive &&
                                            <Button href="https://www.facebook.com/onapaubcn/videos" target="_blank" sx={{marginLeft: 3}}>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    fontWeight: 600,
                                                    fontSize: 16,
                                                    borderRadius: "20px",
                                                    height: 35
                                                }}>
                                                    &nbsp; <Brightness1Icon fontSize="medium" color="white"/>
                                                    <Typography sx={{flexShrink: 0, fontWeight: 600}}>&nbsp; EN DIRECTO&nbsp;</Typography>
                                                </Box>
                                            </Button>
                                        }
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {timetable.map((row, index) => (
            <Dialog key={index} open={openDescription && row.hasDescription && index === showProgram}>
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="programTableHeader" >{row.program}</Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container alignItems={"center"} justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <Box className={classes.boxImage}>
                                <img alt={"icono"} src={row.image} height={"100%"} width={"100%"}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <DialogContentText id="alert-dialog-description" textAlign={"justify"}>
                                <Typography variant="program" color={"black"}>{row.description}</Typography>
                            </DialogContentText>
                        </Grid>
                    </Grid>  			
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={() => setOpenDescription(false)} autoFocus>Cerrar</Button>
                </DialogActions>
            </Dialog>
            ))}
        </>
    );
};

BasicTable.propTypes = {
    timetable: PropTypes.array.isRequired
}

export default BasicTable;