import React from 'react';
import Page from "components/Page";
import {Box, Container} from "@mui/material";
import GuideTable from "./GuideTable";
import Header from "views/radioguide/RadioGuideView/header";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.light,
        paddingBottom: theme.spacing(3),
    },
}));

const RadioGuideView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Programación"
        >
            <Container maxWidth={false}>
                <Header/>
                <Box sx={{mt: 3}}>
                    <GuideTable/>
                </Box>
            </Container>
        </Page>
    );
}
export default RadioGuideView;