import React from 'react';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from '@mui/material';
import {createThemeApp} from 'theme';
import ScrollReset from "components/ScrollReset";
import HomeView from "views/home/HomeView";
import AboutUsView from "views/aboutus/AboutUsView";
import RadioGuideView from "views/radioguide/RadioGuideView";
import DashboardLayout from "layouts/DashboardLayout";
import {THEMES} from "./constants";
import DonationsView from "views/donations/DonationsView";
import PrivacyPolicyView from "views/privacypolicy/PrivacyPolicyView";
import ContactForm from "components/ContactForm";

const history = createBrowserHistory();

function DashLayout() {
    return (
        <DashboardLayout>
            <Outlet/>
        </DashboardLayout>
    )
}

const App = () => {

    const theme = createThemeApp({
        direction: 'ltr',
        theme: THEMES.LIGHT,
        responsiveFontSizes: true,
        typography: {
            fontFamily: [
                'Josefin Sans',
                'sans-serif',
            ].join(','),
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter history={history}>
                <ScrollReset/>
                <Routes>
                    <Route path="/" element={<Navigate to="/app/inicio"/>}/>
                    <Route path="/app" element={<Navigate to="/app/inicio"/>}/>
                    <Route path="app" element={<DashLayout/>}>
                        <Route path="inicio" element={<HomeView/>}/>
                        <Route path="quienessomos" element={<AboutUsView/>}/>
                        <Route path="programacion" element={<RadioGuideView/>}/>
                        <Route path="donaciones" element={<DonationsView/>}/>
                        <Route path="advertencialegal" element={<PrivacyPolicyView/>}/>
                        <Route path="contactaconnosotros" element={<ContactForm/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;