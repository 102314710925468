import axios from 'axios';
import URL from './ServerURL';

const uri = URL + "/programacion";
let response;

export const ProgramacionService = {
    GetProgramacion,
    GetProgramacionPdf
}

async function GetProgramacion(source) {
    await axios.get(uri, {cancelToken: source.token})
        .then(res => response=res)
        .catch(err => response=err.response)
    return response.data;
}

async function GetProgramacionPdf(filename) {
    await axios.get(uri+"/pdf/"+filename, {responseType: 'blob'})
        .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
}