import axios from 'axios';
import URL from './ServerURL';

const uri = URL;
let response;

export const HomeService = {
    GetHome,
}

async function GetHome(source) {
    await axios.get(uri + "/inicio", {cancelToken: source.token})
        .then(res => response = res)
        .catch(err => response = err.response)
    return response.data;
}