import React from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    Toolbar
} from '@mui/material';
import DefaultTopBar from "./DefaultTopBar";
import MobileTopBar from "./MobileTopBar";

const TopBar = ({setMobileNavOpen, scrollHeight, isLocalHome}) => {
    return (
            <AppBar elevation={scrollHeight > 10 || !isLocalHome ? 1 : 0} sx={{bgcolor:`${scrollHeight}` > 10 || !isLocalHome ? "#336666" : "transparent", color:"white"}}>
                <Toolbar>
                    <MobileTopBar setMobileNavOpen={setMobileNavOpen} isScrolling={scrollHeight}/>
                    <Hidden lgDown>
                        <DefaultTopBar isScrolling={scrollHeight}/>
                    </Hidden>
                    <Box
                        pl={2}
                        flexGrow={1}
                    />
                </Toolbar>
            </AppBar>
    );
};

TopBar.propTypes = {
    setMobileNavOpen: PropTypes.func,
    scrollHeight: PropTypes.number,
    isLocalHome: PropTypes.bool
};

export default TopBar;