import React, {useEffect, useState} from 'react';
import Page from "components/Page";
import {makeStyles} from "@mui/styles";
import {Box, Tab, Tabs} from "@mui/material";
import RadioHistory from "./RadioHistory";
import Author from "./Author";
import axios from "axios";
import {QuienessomosService} from "Services/Server/QuienessomosService";
import OndaPazTeam from "./Team";
import LoadingScreen from "components/LoadingScreen";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {},
    tab: {
        "&.MuiButtonBase-root.MuiTab-root": {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '18px',
            [theme.breakpoints.down("md")]: {
                fontSize: '15px',
            },
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AboutUsView = () => {
    const classes = useStyles();
    const [info, setInfo] = useState(null);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getQuienessomos = async (source) => {
        const data = await QuienessomosService.GetQuienessomos(source);
        setInfo(data);
    }

    useEffect(() => {
        const source = axios.CancelToken.source();

        async function fetchData() {
            if (!info) {
                await getQuienessomos(source);
            }
        }

        fetchData();

        return () => {
            source.cancel();
        };
    }, [info]);

    return (
        <Page
            className={classes.root}
            title="Quiénes Somos"
        >
            <Box sx={{ width: '100%' }}>
                {info ?
                <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab className={classes.tab} label="Historia" {...a11yProps(0)} />
                        <Tab className={classes.tab} label="Fundador" {...a11yProps(1)} />
                        <Tab className={classes.tab} label="Equipo" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <RadioHistory info={info.radio}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Author author={info.founder}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <OndaPazTeam team={info.team}/>
                </TabPanel>
                </>
                : <LoadingScreen/>
                }
            </Box>
        </Page>
    );
}
export default AboutUsView;