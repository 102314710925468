import React, {useState} from "react";
import {
    SpeedDial,
    SpeedDialAction,
} from '@mui/material';
import {Facebook, Instagram, WhatsApp, YouTube, Favorite, FavoriteBorder} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    heartColor: {
        color: 'white'
    },
    '@keyframes heartBeat': {
        to: {
            transform: 'scale(1.8)'
        }
    },
    heartBeat: {
        animationName: '$heartBeat',
        animation: '.55s infinite alternate',
        transformOrigin: 'center'
    }
}));

const SpeedDialSocialMedia = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [icon, setIcon] = useState(<FavoriteBorder className={clsx(classes.heartBeat,classes.heartColor)}/>);

    const socialLinks = {
        Facebook: 'https://es-la.facebook.com/ondapazbcn/',
        Instagram: 'https://www.instagram.com/onapaubcn/',
        WhatsApp: 'https://wa.me/34657999230',
        Youtube: 'https://www.youtube.com/@julioperezyoutube'
    };

    const actions = [
        {icon: <WhatsApp style={{fill: '#25D366'}} onClick={() => handleClick('WhatsApp')}/>, name: 'WhatsApp'},
        {icon: <Instagram style={{fill: '#dd2a7b'}} onClick={() => handleClick('Instagram')}/>, name: 'Instagram'},
        {icon: <Facebook style={{fill: '#3b5998'}} onClick={() => handleClick('Facebook')}/>, name: 'Facebook'},
        {icon: <YouTube style={{fill: '#e1251d'}} onClick={() => handleClick('Youtube')}/>, name: 'Youtube'},
    ];

    const handleClick = (network) => {
        handleClose();
        window.open(socialLinks[network], '_blank');
    };

    const handleOpen = () => {
        setOpen(true);
        setIcon(<Favorite style={{fill: '#d92a55'}}/>);
    };

    const handleClose = () => {
        setOpen(false);
        setIcon(<FavoriteBorder className={clsx(classes.heartBeat,classes.heartColor)}/>);
    };

    return (
        <SpeedDial
            ariaLabel="SpeedDial social media"
            sx={{
                position: 'fixed',
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 20,
                left: 'auto',
                zIndex: 100
            }}
            icon={icon}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            FabProps={{
                sx: {
                    bgcolor: '#f8af39',
                    '&:hover': {
                        bgcolor: 'rgb(210,146,48)'
                    },
                }
            }}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    id={action.name}
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                />
            ))}
        </SpeedDial>
    );
}

export default SpeedDialSocialMedia;