import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import NavBar from './NavBar';
import BottomBar from "./BottomBar";
import Footer from "./Footer";
import TopBar from "./TopBar";
import {RadioStation} from "utils/RadioStation";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.light,
        display: 'flex',
        flex: '1 1 auto',
        height: '100%',
    },
    onScrollContentPadding: {
        marginTop: 90,
        [theme.breakpoints.down("lg")]: {
            marginTop: 64,
        },
        [theme.breakpoints.down("md")]: {
            marginTop: 56,
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 48,
        },
        width: '100%'
    },
    contentPadding: {
        width: '100%'
    }
}));

const DashboardLayout = ({children}) => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [isLocalHome, setIsLocalHome] = useState(false);
    const location = useLocation()

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollHeight(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        sessionStorage.setItem("radioStatus", RadioStation.GetRadioStatus())
        setIsLocalHome(location.pathname === "/app/inicio");
    }, [scrollHeight, location]);

    return (
        <>           
            <div className={classes.root}>
                <TopBar
                    setMobileNavOpen={setMobileNavOpen}
                    scrollHeight={scrollHeight}
                    isLocalHome={isLocalHome}
                />
                <NavBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <div className={isLocalHome ? classes.contentPadding : classes.onScrollContentPadding}>           
                    {children}
                    <Footer/>
                </div>
                <BottomBar scrollHeight={scrollHeight} isLocalHome={isLocalHome}/>
            </div>
        </>
    )
};

DashboardLayout.propTypes = {
    children: PropTypes.node
};

export default DashboardLayout;