import React from 'react';
import PropTypes from 'prop-types';
import SpeedDialSocialMedia from "./SpeedDialSocialMedia";
import PlayRadio from "./PlayRadio";

const BottomBar = ({scrollHeight, isLocalHome}) => {
    return (
        <>
            {scrollHeight > 10 || !isLocalHome ?
                <>
                    <PlayRadio/>
                    <SpeedDialSocialMedia/>
                </>
               : null
            }
        </>
    );
};

BottomBar.propTypes = {
    scrollHeight: PropTypes.number,
    isLocalHome: PropTypes.bool
};

export default BottomBar;