import React from "react";
import {Grid, Typography, Box, Button, Divider, useMediaQuery} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import { FaHandPointer } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
    gridArea: {
        width: '100%',
        backgroundColor: '#ffffff',
        color: 'white'
    },
    imageHeight: {
        [theme.breakpoints.up("xl")]: {
            height: 300
        },
        [theme.breakpoints.between("lg","xl")]: {
            height: 250
        },
        [theme.breakpoints.between("sm","lg")]: {
            height: 200
        },
        [theme.breakpoints.between("xs","sm")]: {
            width: window.innerWidth - 20
        },
    },
    boxInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    },
    iconSize: {
        "& .MuiButton-startIcon": {
            '& > *:first-child': {
                fontSize: 38,
                [theme.breakpoints.down("lg")]: {
                    fontSize: 38,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 23,
                },
                [theme.breakpoints.down("sm")]: {
                    fontSize: 18,
                },
            },
        }
    }
}));

const SuicidePrevention = ({info}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <>
            <Box pl={3} py={10}>
                <Typography variant={"titles"} color="text.primary"> {info.name}</Typography>
            </Box>
            <Grid pb={10} spacing={6} container direction="row" alignItems="center" justifyContent="center" className={classes.gridArea}>
                <Grid item md={5.5} xs={12}>
                    <Box className={classes.boxInfo}>
                        <img alt={"icono"} src={info.image} className={classes.imageHeight}/>
                    </Box>
                </Grid>
                {mobile ?
                    <Grid item xs={12}>
                        <Divider variant="middle" flexItem/>
                    </Grid>
                    :  <Divider orientation="vertical" variant="middle" flexItem/>}
                <Grid item md={5.5} xs={12}>
                    <Box className={classes.boxInfo} paddingX={4}>
                        <Typography variant={"homeh1"} sx={{color: "#204640"}} mb={4}> {info.radioAction} </Typography>
                        <Typography variant={"homeh2"} sx={{color: "#204640"}} mb={4}> ¡NO ESTAS SOLO! </Typography>
                    </Box>
                    <Box className={classes.boxInfo}>
                        <Button variant="contained"
                                className={classes.iconSize}
                                sx={{
                                    backgroundColor: "primary.dark",
                                    '&:hover': {
                                        backgroundColor: "#ce6d69",
                                    },
                                }}
                                startIcon={<FaHandPointer style={{color: "white"}}/>}
                                onClick={() => {
                                    navigate(
                                        "/app/contactaconnosotros",
                                        {
                                            state: {
                                                id: "prevencion suicidio",
                                                name: "Ayuda",
                                                title: "Estamos para ayudarte. Rellena el siguiente formulario:"
                                            }
                                        }
                                    )
                                }}
                        >
                            <Typography variant={"coverButton"} color={"white"}>
                                ESCRIBENOS
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

SuicidePrevention.propTypes = {
    info: PropTypes.object
};

export default SuicidePrevention;