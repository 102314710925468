import React from 'react';
import {makeStyles} from "@mui/styles";
import {useMediaQuery, Grid, Button, SvgIcon, Typography} from "@mui/material";
import {DownloadRounded} from "@mui/icons-material";
import {ProgramacionService} from "Services/Server/ProgramacionService";

const useStyles = makeStyles((theme) => ({
    padY: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
    },
    padB: {
        paddingBottom: theme.spacing(3),
    },
    iconSize: {
        "& .MuiButton-startIcon": {
            '& > *:first-child': {
                fontSize: 36,
                [theme.breakpoints.down("lg")]: {
                    fontSize: 30,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 26,
                },
            },
        }
    }
}));

const Header = () => {
    const classes = useStyles();
    const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));


    const handleDownload = async (filename) => {
        await ProgramacionService.GetProgramacionPdf(filename);
    }

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                sx={{mt: 2}}
            >
                <Grid item lg={7} md={7} xs={12}>
                    <Typography
                        variant={"titles"}
                        color="text.primary"
                    >
                        Programación
                    </Typography>
                </Grid>
                <Grid item lg={2.5} md={2.5} xs={12} pr={2} sx={{display:"flex", justifyContent: "flex-end", alignItems: "center"}} className={mobile ? classes.padB : null}>
                    <Button
                        variant="contained"
                        className={classes.iconSize}
                        sx={{
                            color: "white",
                            backgroundColor: "secondary.main",
                            '&:hover': {
                                color: "#fff4eb",
                                backgroundColor: "#254a4a"
                            },
                        }}
                        startIcon={
                            <SvgIcon fontSize="medium">
                                <DownloadRounded/>
                            </SvgIcon>
                        }
                        onClick={() => handleDownload("programacion.pdf")}
                    >
                        <Typography variant={"downloadRadioGuide"}>
                           Programación
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default Header;