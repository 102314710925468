import axios from 'axios';
import URL from './ServerURL';

const uri = URL;
let response;

export const EmailService = {
    PostContactInfo,
}

async function PostContactInfo(data) {
    let config = {
        method: 'post',
        url: uri + "/contact",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
        data: data
    }
    await axios(config)
        .then(async function (res) {
            response = res;
            console.log("axios res", response)
        })
        .catch(err => {
            response = err.response;
            console.log("axios error", response)
        });
    return response;
}
