import React from "react";
import {Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    subtitles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(3),
        }
    },
    boxInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    }
}));

const BoxVolunteers = () => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.subtitles}/>
            <Box className={classes.boxInfo}>
                <Box/>
            </Box>
        </>
    );
}

BoxVolunteers.propTypes = {
    title: PropTypes.string,
    members: PropTypes.array
};

export default BoxVolunteers;