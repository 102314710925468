import React, {useEffect, useState} from 'react';
import Page from "components/Page";
import Cover from "./Cover";
import WebsitesOfInterest from "./WebsitesOfInterest";
import RadioFrequencies from "./RadioFrequencies";
import axios from "axios";
import {HomeService} from "Services/Server/HomeService";
import SuicidePrevention from "./SuicidePrevention";

const HomeView = () => {
    const [homeInfo, setHomeInfo] = useState(null);

    const getHomeInfo = async (source) => {
        const data = await HomeService.GetHome(source, setHomeInfo);
        if (data) setHomeInfo(data);
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        async function fetchData() {
            if(!homeInfo) {
                await getHomeInfo(source);
            }
        }
        fetchData();

        return () => {
            source.cancel();
        };
    }, [homeInfo]);

    return (
        <Page
            title="Ona Pau"
        >
            {homeInfo &&
                <>
                    <Cover cover={homeInfo.cover}/>
                    <RadioFrequencies frequencies={homeInfo.frequencies}/>
                    <SuicidePrevention info={homeInfo.suicidePrevention}/>
                    <WebsitesOfInterest webs={homeInfo.relatedPages}/>
                </>
            }
        </Page>
    );
};

export default HomeView;