const url = "https://srv7021.dns-lcinternet.com/8064/stream";
const radioStation = new Audio(url);

export const RadioStation = {
    UpdateRadioStatus,
    GetRadioStatus
}

function GetRadioStatus() {
    if (radioStation.paused) return "paused";
    else return "playing";
}

function UpdateRadioStatus(paused) {
    if (paused) radioStation.pause();
    else radioStation.play();
}