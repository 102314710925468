import axios from 'axios';
import URL from './ServerURL';

const uri = URL + "/utils";
let response;

export const UtilsService = {
    GetSlogan,
    GetFooter
}

async function GetSlogan(source) {
    await axios.get(uri, {cancelToken: source.token})
        .then(res => response=res)
        .catch(err => response=err.response)
    return response.data.slogan;
}

async function GetFooter(source) {
    await axios.get(uri, {cancelToken: source.token})
        .then(res => response=res)
        .catch(err => response=err.response)
    return response.data.footer;
}