import React from 'react';
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    boxInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    },
    boxInfoLeft: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "left",
        alignItems: "left",
    }
}));

const Privacy = () => {
    const classes = useStyles();

    return (
        <>
            <Box pb={{xs: 8, sm: 5}} px={4} className={classes.boxInfoLeft}>
                <Typography variant={"sharedSubTitle"} color={"text.secondary"} sx={{marginBottom: 3, marginLeft: 3}} >
                    LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN (LSSI)
                </Typography>
                <Typography variant={"sharedBody"} color={"text.secondary"} textAlign={'justify'}>
                    ASOCIACION CULTURAL RADIO TELEVISION ONDA PAZ, responsable del sitio web, en adelante RESPONSABLE,
                    pone a disposición de los usuarios el presente documento, con el que pretende dar cumplimiento a las
                    obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de
                    Comercio Electrónico (LSSICE), BOE N º 166, así como informar a todos los usuarios del sitio web respecto a
                    cuáles son las condiciones de uso.<br/><br/>
                    Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a la observancia y
                    cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualquier otra disposición legal que
                    fuera de aplicación.<br/><br/>
                    ASOCIACION CULTURAL RADIO TELEVISION ONDA PAZ se reserva el derecho de modificar cualquier tipo de
                    información que pudiera aparecer en el sitio web, sin que exista obligación de preavisar o poner en
                    conocimiento de los usuarios dichas obligaciones, entendiéndose como suficiente con la publicación en los sitios
                    web de ASOCIACION CULTURAL RADIO TELEVISION ONDA PAZ.
                </Typography>
            </Box>
            <Box pb={{xs: 8, sm: 5}} px={4} className={classes.boxInfoLeft}>
                <Typography variant={"sharedSubTitle"} color={"text.secondary"} sx={{marginBottom: 3, marginLeft: 3}} >
                    1. DATOS IDENTIFICATIVOS
                </Typography>
                <Typography variant={"sharedBody"} color={"text.secondary"}>
                    Nombre de dominio: www.ondapaz.fm<br/>
                    Nombre comercial: ASOCIACION CULTURAL RADIO TELEVISION ONDA PAZ<br/>
                    Denominación social: ASOCIACION CULTURAL RADIO TELEVISION ONDA PAZ<br/>
                    NIF: G62686696<br/>
                    Domicilio social: C/ LLULL, 48, 6º 1ª, 08005, BARCELONA<br/>
                    Teléfono: 932466797<br/>
                    Correo electrónico: info@ondapaz.fm
                </Typography>
            </Box>
            <Box pb={{xs: 8, sm: 5}} px={4} className={classes.boxInfoLeft}>
                <Typography variant={"sharedSubTitle"} color={"text.secondary"} sx={{marginBottom: 3, marginLeft: 3}} >
                    2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
                </Typography>
                <Typography variant={"sharedBody"} color={"text.secondary"} textAlign={'justify'}>
                    El sitio web, incluyendo a título enunciativo, pero no limitativo su programación, edición, compilación y demás
                    elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos, son propiedad del
                    RESPONSABLE o, si es el caso, dispone de licencia o autorización expresa por parte de los autores. Todos los
                    contenidos del sitio web se encuentran debidamente protegidos por la normativa de propiedad intelectual e
                    industrial, así como inscritos en los registros públicos correspondientes.<br/><br/>
                    Independientemente de la finalidad para la que fueran destinados, la reproducción total o parcial, uso,
                    explotación, distribución y comercialización, requiere en todo caso de la autorización escrita previa por parte
                    del RESPONSABLE. Cualquier uso no autorizado previamente se considera un incumplimiento grave de los
                    derechos de propiedad intelectual o industrial del autor.<br/><br/>
                    Los diseños, logotipos, texto y/o gráficos ajenos al RESPONSABLE y que pudieran aparecer en el sitio web,
                    pertenecen a sus respectivos propietarios, siendo ellos mismos responsables de cualquier posible controversia
                    que pudiera suscitarse respecto a los mismos. El RESPONSABLE autoriza expresamente a que terceros puedan
                    redirigir directamente a los contenidos concretos del sitio web, y en todo caso redirigir al sitio web principal
                    de ASOCIACION CULTURAL RADIO TELEVISION ONDA PAZ.<br/><br/>
                    El RESPONSABLE reconoce a favor de sus titulares los correspondientes derechos de propiedad intelectual e
                    industrial, no implicando su sola mención o aparición en el sitio web la existencia de derechos o
                    responsabilidad alguna sobre los mismos, como tampoco respaldo, patrocinio o recomendación por parte del
                    mismo.<br/><br/>
                    Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad
                    intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, puede hacerlo a través del
                    correo electrónico INFO@ONDAPAZ.FM

                </Typography>
            </Box>
            <Box pb={{xs: 8, sm: 5}} px={4} className={classes.boxInfoLeft}>
                <Typography variant={"sharedSubTitle"} color={"text.secondary"} sx={{marginBottom: 3, marginLeft: 3}}>
                    3. EXENCIÓN DE RESPONSABILIDADES
                </Typography>
                <Typography variant={"sharedBody"} color={"text.secondary"} sx={{marginBottom: 3}} textAlign={'justify'}>
                    El RESPONSABLE se exime de cualquier tipo de responsabilidad derivada de la información publicada en su
                    sitio web siempre que esta información haya sido manipulada o introducida por un tercero ajeno al mismo.
                </Typography>
                <Typography variant={"sharedSubTitle"} color={"text.secondary"} sx={{marginBottom: 3, marginLeft: 3}}>
                    Política de enlaces
                </Typography>
                <Typography variant={"sharedBody"} color={"text.secondary"} sx={{marginBottom: 3}} textAlign={'justify'}>
                    Desde el sitio web, es posible que se redirija a contenidos de terceros sitios web. Dado que el RESPONSABLE
                    no puede controlar siempre los contenidos introducidos por los terceros en sus respectivos sitios web, no
                    asume ningún tipo de responsabilidad respecto a dichos contenidos. En todo caso, procederá a la retirada
                    inmediata de cualquier contenido que pudiera contravenir la legislación nacional o internacional, la moral o el
                    orden público, procediendo a la retirada inmediata de la redirección a dicho sitio web, poniendo en
                    conocimiento de las autoridades competentes el contenido en cuestión.<br/><br/>
                    El RESPONSABLE no se hace responsable de la información y contenidos almacenados, a título enunciativo,
                    pero no limitativo, en foros, chats, generadores de blogs, comentarios, redes sociales o cualquier otro medio
                    que permita a terceros publicar contenidos de forma independiente en la página web del RESPONSABLE. Sin
                    embargo, y en cumplimiento de lo dispuesto en los artículos 11 y 16 de la LSSICE, se pone a disposición de
                    todos los usuarios, autoridades y fuerzas de seguridad, colaborando de forma activa en la retirada o, en su
                    caso, bloqueo de todos aquellos contenidos que puedan afectar o contravenir la legislación nacional o
                    internacional, los derechos de terceros o la moral y el orden público. En caso de que el usuario considere que
                    existe en el sitio web algún contenido que pudiera ser susceptible de esta clasificación, se ruega lo notifique
                    de forma inmediata al administrador del sitio web.<br/><br/>
                    Este sitio web ha sido revisado y probado para que funcione correctamente. En principio, puede garantizarse
                    el correcto funcionamiento los 365 días del año, 24 horas al día. Sin embargo, el RESPONSABLE no descarta la
                    posibilidad de que existan ciertos errores de programación, o que acontezcan causas de fuerza mayor,
                    catástrofes naturales, huelgas o circunstancias semejantes que hagan imposible el acceso a la página web.
                </Typography>
            </Box>
            <Box pb={{xs: 8, sm: 5}} px={4} className={classes.boxInfoLeft}>
                <Typography variant={"sharedSubTitle"} color={"text.secondary"} sx={{marginBottom: 3, marginLeft: 3}} >
                    4. LEY APLICABLE Y JURISDICCIÓN
                </Typography>
                <Typography variant={"sharedBody"} color={"text.secondary"} textAlign={'justify'}>
                    Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de las
                    actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten expresamente
                    las partes, siendo competentes para la resolución de todos los conflictos derivados o relacionados con su uso
                    los Juzgados y Tribunales más cercanos a BARCELONA.
                </Typography>
            </Box>
        </>
    )

}

export default Privacy;