import axios from 'axios';
import URL from './ServerURL';

const uri = URL + "/quienessomos";
let response;

export const QuienessomosService = {
    GetQuienessomos,
}

async function GetQuienessomos(source) {
    await axios.get(uri, {cancelToken: source.token})
        .then(res => response=res)
        .catch(err => response=err.response)
    if(response.data) return response.data;
}