import React from 'react';
import {Grid, Typography} from "@mui/material";

const Header = () => {

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                sx={{pt: 2}}
            >
                <Grid item>
                    <Typography
                        variant={"titles"}
                        color="text.primary"
                    >
                        Donaciones
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default Header;