import React from "react";
import {Card, CardContent, CardMedia, CardActionArea, Grid, Typography, Box} from '@mui/material';
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import RadioFrequencies from "./RadioFrequencies";

const useStyles = makeStyles(theme => ({
    cardActArea: {
        height: 300,
        [theme.breakpoints.down('md')]: {
            height: 280,
        },
        [theme.breakpoints.down('sm')]: {
            height: 260,
        }
    },
    cardMedia: {
        height: 150,
        [theme.breakpoints.down('md')]: {
            height: 130,
        },
        [theme.breakpoints.down('sm')]: {
            height: 100,
        }
    }
}));

const WebsitesOfInterest = ({webs}) => {
    const classes = useStyles();

    return (
        <>
            <Box pl={3} pt={10}>
                <Typography variant={"titles"} color="text.primary"> Páginas de interés </Typography>
            </Box>
            <Grid pt={3} pb={10} spacing={2} container justifyContent="center" alignItems="center">
                { webs && webs.map((web, index) => (
                    <Grid item key={index} lg={3} sm={6} xs={12}>
                        <Card>
                            <CardActionArea className={classes.cardActArea} href={web.url} target="_blank">
                                <CardMedia
                                    component="img"
                                    className={classes.cardMedia}
                                    image={web.image}
                                    alt={web.id}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant={"h4"} color={"text.primary"} component="div">
                                        {web.name}
                                    </Typography>
                                    <Typography variant={"body1"} color={"text.secondary"}>
                                        {web.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

RadioFrequencies.propTypes = {
    webs: PropTypes.array,
};

export default WebsitesOfInterest;