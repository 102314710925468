import React from 'react';
import Page from "components/Page";
import {makeStyles} from "@mui/styles";
import {Container} from "@mui/material";
import Header from "./header";
import DonationsTemp from './DonationsTemp';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const DonationsView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Donaciones"
        >
            <Container maxWidth={false}>
                <Header/>
                <DonationsTemp/>
            </Container>
        </Page>
    );
}
export default DonationsView;