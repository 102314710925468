import React from 'react';
import Page from "components/Page";
import {Box, Container} from "@mui/material";
import Privacy from "./Privacy";
import Header from "./header";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.light,
    },
}));

const PrivacyPolicyView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Advertencia legal"
        >
            <Container maxWidth={false}>
                <Header/>
                <Box sx={{mt: 3}}>
                    <Privacy/>
                </Box>
            </Container>
        </Page>
    );
}
export default PrivacyPolicyView;