import React, {useState} from "react";
import {Grid, Typography, Box, Paper, Button, Divider, useMediaQuery, SvgIcon} from "@mui/material";
import {styled} from "@mui/material/styles";
import {makeStyles, useTheme} from "@mui/styles";
import PropTypes from "prop-types";
import LaunchIcon from '@mui/icons-material/Launch';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
    gridArea: {
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        alignItems:"center",
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    },
    boxInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    },
    iconSize: {
        "& .MuiButton-startIcon": {
            '& > *:first-child': {
                fontSize: 40,
                [theme.breakpoints.down("lg")]: {
                    fontSize: 30,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 25,
                },
                [theme.breakpoints.down("sm")]: {
                    fontSize: 20,
                },
            },
        }
    },
    mobileStepper: {
        '& .MuiMobileStepper-root.Mui-completed': {
            backgroundColor: 'rgba(33, 159, 148,0.4)',
        }
    }
}));

//carousel de la imagen entera
const ImageCarousel = styled(Paper)(({theme}) => ({
    position: "relative",
    height: 300,
    [theme.breakpoints.down("sm")]: {
        width: "100% !important", // Overrides inline-style
        height: 150
    },
    "&:hover, &.Mui-focusVisible": {
        zIndex: 1,
        "& .MuiImageBackdrop-root": {
            opacity: 0.15
        },
        "& .MuiImageMarked-root": {
            opacity: 0
        },
        "& .MuiBox-root": {
            background: 'rgba(33, 159, 148,0.4)',
        }
    }
}));

//subtitulo de la imagen del carousel
const ImageCaption = styled("span")(({theme}) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    color: theme.palette.common.white
}));

//link a la imagen
const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
});

//imagen opaca del principio
const ImageBackdrop = styled("span")(({theme}) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity")
}));

const RadioFrequencies = ({frequencies}) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = frequencies.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <>
            <Grid container mt={0.1} spacing={6} className={classes.gridArea}>
                <Grid item xs={12} md={5.5}>
                    <Box className={classes.boxInfo}>
                        <Box mb={4}>
                            <Typography variant={"homeh1"} color={theme.palette.primary.main} mb={4}> Podcasts de nuestros programas estelares </Typography>
                        </Box>
                        <Box>
                            <Button variant="contained" color="secondary" sx={{'&:hover': {bgcolor: '#254a4a'}}} className={classes.iconSize} target="_blank" startIcon={<LaunchIcon sx={{color: "white"}}/>} href={"https://www.spreaker.com/user/ondapazbarcelona"}>
                                <Typography variant={"coverButton"} color={"white"}>
                                    Haz click aquí
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {mobile ?
                    <Grid item xs={12}>
                        <Divider variant="middle" flexItem> <Typography variant={"homeh1"} color={"primary"}> O </Typography></Divider>
                    </Grid>
                :  <Divider orientation="vertical" variant="middle" flexItem> <Typography variant={"homeh1"} color={"primary"}> O </Typography></Divider>}
                <Grid item xs={12} md={5.5}>
                    <Box className={classes.boxInfo}>
                        <Box mb={4}>
                            <Typography variant={"homeh1"} color={"primary"}> Sintoníza nuestra frecuencia de radio </Typography>
                        </Box>
                        <Box width={"80%"}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {frequencies.map((step, index) => (
                                    <Box key={index}>
                                        <ImageCarousel
                                            key={step.name}
                                            style={{
                                                width: '100%'
                                            }}
                                        >
                                            <ImageSrc style={{backgroundImage: `url(${step.image})`}}/>
                                            <ImageBackdrop className="MuiImageBackdrop-root"/>
                                            <ImageCaption>
                                                <Box
                                                    width="100%"
                                                    sx={{
                                                        background: 'rgba(51,102,102,0.9)',
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <Typography
                                                        component="span"
                                                        variant="radioFreq"
                                                        color="inherit"
                                                        textAlign="center"
                                                        sx={{ p: 2 }}
                                                    >
                                                        {step.name}
                                                    </Typography>
                                                </Box>
                                            </ImageCaption>
                                        </ImageCarousel>
                                    </Box>
                                ))}
                            </AutoPlaySwipeableViews>
                            <MobileStepper
                                sx={{ bgcolor: 'transparent' }}
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                    >
                                        {theme.direction === 'rtl' ? (
                                            <SvgIcon fontSize="large">
                                                <KeyboardArrowLeft />
                                            </SvgIcon>
                                        ) : (
                                            <SvgIcon fontSize="large">
                                                <KeyboardArrowRight/>
                                            </SvgIcon>
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? (
                                                <SvgIcon fontSize="large">
                                                    <KeyboardArrowRight/>
                                                </SvgIcon>
                                        ) : (
                                            <SvgIcon fontSize="large">
                                                <KeyboardArrowLeft />
                                            </SvgIcon>
                                        )}
                                    </Button>
                                }
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

RadioFrequencies.propTypes = {
    frequencies: PropTypes.array,
};

export default RadioFrequencies;