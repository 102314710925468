import {createTheme} from "@mui/material";

const theme = createTheme();

const Typography = {
  h1: {
    fontWeight: 500,
    fontSize: 30,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
    [theme.breakpoints.down("lg")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 11,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 8,
    },
  },
  body1: {
    fontWeight: 200,
    fontSize: 16,
    letterSpacing: '0.95px'
  },

  //layout
   topBar: { //botones de la barra menú superior
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: '0.9px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  contactButtons: {
    fontWeight: 600,
    fontSize: 19,
    [theme.breakpoints.between("md","lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    lineHeight: '3px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  footerTitles: {
    fontWeight: 800,
    fontSize: 24,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  footerContent: {
    fontWeight: 300,
    fontSize: 22,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },

  //titulos
  titles: { //titulos de las cada vista (Quienes Somos, Programacion y Donaciones)
    fontWeight: 800,
    fontSize: 38,
    [theme.breakpoints.down("lg")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 24,
    },
    letterSpacing: '0.24px',
    textTransform: 'uppercase',
    fontFamily: 'Noto Sans, sans-serif'
  },

  //shared title of a paragraph
  sharedSubTitle: {
    fontWeight: 600,
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 20,
    },
    letterSpacing: '0.9px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  //shared subtitle of a paragraph
  sharedSubSubTitle: {
    fontWeight: 600,
    fontSize: 26,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 16,
    },
    letterSpacing: '0.9px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  //shared content
  sharedBody: {
    fontWeight: 400,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 18,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  //shared content small
  sharedBodySmall: {
    fontWeight: 400,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 14,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  //shared content light
  sharedBodyLight: {
    fontWeight: 300,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },

  //homeview
  coverBlack: { //titulo cover
    fontSize: 80,
    [theme.breakpoints.down("lg")]: {
      fontSize: 70,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 60,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    fontFamily: 'LoveloBlack, sans-serif'
  },
  coverBlackH2: { //titulo h2 cover
    fontSize: 50,
    [theme.breakpoints.down("lg")]: {
      fontSize: 45,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 35,
    },
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    fontFamily: 'LoveloBlack, sans-serif'
  },
  coverLineBold: { //titulo cover
    fontSize: 80,
    [theme.breakpoints.down("lg")]: {
      fontSize: 70,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 60,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    fontFamily: 'LoveloLineBold, sans-serif'
  },
  coverLineLight: { //titulo cover
    fontSize: 80,
    [theme.breakpoints.down("lg")]: {
      fontSize: 70,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 60,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    fontFamily: 'LoveloLineLight, sans-serif'
  },
  coverSubtitle: { //slogan cover
    fontWeight: 600,
    fontSize: 60,
    [theme.breakpoints.down("lg")]: {
      fontSize: 50,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    lineHeight: 'normal',
    fontFamily: 'Noto Sans, sans-serif'
  },
  coverSubtitleH2: { //slogan h2 cover
    fontWeight: 600,
    fontSize: 40,
    [theme.breakpoints.down("lg")]: {
      fontSize: 35,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
    lineHeight: 'normal',
    fontFamily: 'Noto Sans, sans-serif'
  },
  coverButton: {
    fontWeight: 500,
    fontSize: 30,
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    letterSpacing: '2.5px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  radioFreq:  { //texto de las emisoras (95.. bcn...)
    fontWeight: 700,
    fontSize: 28,
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  homeh1: { //titulo home
    fontWeight: 800,
    fontSize: 35,
    [theme.breakpoints.down("lg")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    fontFamily: 'Noto Sans, sans-serif'
  },
  homeh2: { //titulo home
    fontWeight: 800,
    fontSize: 30,
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    fontFamily: 'Noto Sans, sans-serif'
  },

  //quienesomos - radiohistory
  infoRadio: {
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 20,
    },
    fontWeight: 600,
    letterSpacing: '2.0px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  radioValues: { //valores de la radio (historia)
    fontWeight: 500,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 18,
    },
    letterSpacing: '0.55px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  slogan: {
    fontSize: 50,
    [theme.breakpoints.down("lg")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
    },
    fontWeight: 600,
    letterSpacing: '0.95px',
    fontFamily: 'LoveloLineLight, serif'
  },
  sloganFilled: {
    fontSize: 50,
    [theme.breakpoints.down("lg")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
    },
    fontWeight: 600,
    letterSpacing: '0.95px',
    fontFamily: 'LoveloBlack, serif'
  },

  //quienessomos - author
  quote: {
    fontSize: 50,
    [theme.breakpoints.down("lg")]: {
      fontSize: 35,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(300)]: {
      fontSize:  10,
    },
    fontStyle: 'italic',
    fontFamily: 'Noto Serif, serif'
  },
  quoteauthor: {
    fontWeight: 500,
    fontSize: 30,
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
    [theme.breakpoints.down(300)]: {
      fontSize: 6,
    },
    paddingBottom: '8px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  //quienessomos members
  memberTitle: {
    fontWeight: 600,
    fontSize: 21,
    [theme.breakpoints.down("lg")]: {
      fontSize: 19,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    letterSpacing: '1.5px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  memberRol: {
    fontWeight: 500,
    fontSize: 16.5,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
    letterSpacing: '0.6px',
    fontFamily: 'Noto Sans, sans-serif'
  },

  //programacion
  downloadRadioGuide: {
    fontWeight: 400,
    fontSize: 18,
    [theme.breakpoints.down("lg")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    letterSpacing: '0.5px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  programDays: { //dias de la semana
    fontWeight: 700,
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif',
    fontSize: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  programTableHeader: { //cabecera de cada dia (hora, programa)
    fontWeight: 700,
    fontSize: 20,
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  program: { //fila de cada programa
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },

  //donations
  donacionesTitle:{
    fontWeight: 600,
    fontSize: 28,
    [theme.breakpoints.down("lg")]: {
      fontSize: 26,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
    letterSpacing: '0.9px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  donacionesContent:{
    fontWeight: 400,
    fontSize: 26,
    [theme.breakpoints.down("lg")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    letterSpacing: '0.5px',
    fontFamily: 'Noto Sans, sans-serif'
  },
  donacionesId: {
    fontWeight: 700,
    fontSize: 30,
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 26,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    letterSpacing: '0.95px',
    fontFamily: 'Noto Sans, sans-serif'
  },

  //--------
  overline: {
    fontWeight: 500
  },
  xmasFont: { //titulo navidad
    fontSize: 90,
    [theme.breakpoints.down("lg")]: {
      fontSize: 80,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 70,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 60,
    },
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    paddingBottom: '20px',
    fontFamily: 'xmasFont, sans-serif'
  },
};

export default Typography;
