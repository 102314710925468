import React from 'react';
import {makeStyles} from "@mui/styles";
import {Box, Container, Divider, Typography} from "@mui/material";
import AuthorImage from "./AuthorImage";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 24,
        marginBottom: 16
    },
    centerText: {
        display: "flex",
        justifyContent: 'center',
        textAlign: "center",
    },
    paragraph: {
        marginBottom: theme.spacing(4),
        textAlign: 'center'
    },
    email: {
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    justAlignText: {
        textAlign: 'center'
    },
    justifyText: {
        textAlign: 'justify'
    },
    margin: {
        marginBottom: theme.spacing(2),
    }
}));

const Author = ({author}) => {
    const classes = useStyles();

    return (
        <Box mt={2}>
            <Box className={classes.root}>
                <Typography variant={"titles"} color="text.primary">
                    Julio Pérez
                </Typography>
            </Box>

            <AuthorImage img={author.image}/>

            <Container className={classes.justAlignText} sx={{
                marginY: 5,
            }}>
                {author.description && author.description.map((paragraph, index) => (
                    <Box key={index} className={author.description.length-1 !== index ? classes.paragraph : classes.justAlignText}>
                        <Typography key={paragraph.id} variant={"sharedBody"} color={"text.secondary"}> {paragraph.text}</Typography>
                    </Box>
                ))}
            </Container>
            <Divider/>
            <Container sx={{
                marginY: 5,
            }}>
                <Box className={clsx(classes.justAlignText,classes.margin)}>
                    <Typography variant={"sharedBody"} color={"text.secondary"}>{author.responsibilities.text}</Typography>
                </Box>
                <Box className={classes.justifyText}>
                    <ul>
                    {author.responsibilities.list && author.responsibilities.list.map((item, index) => (
                        <Typography key={index} variant={"sharedBody"} color={"text.secondary"}> <li className={classes.margin}> {item} </li> </Typography>
                    ))}
                    </ul>
                </Box>
            </Container>

            {author.contactInfo &&
            <>
                <Divider/>
                <Container className={classes.justAlignText} sx={{
                    marginY: 5,
                }}>
                    <Box mb={3}>
                        <Typography variant={"sharedSubSubTitle"} color={"text.primary"}>Datos de contacto</Typography>
                    </Box>
                    {author.contactInfo.map((item, index) => (
                        <Box key={index} className={author.contactInfo.length-1 !== index ? classes.email : classes.justAlignText}>
                            <Typography key={index} variant={"sharedBody"} color={"text.secondary"}>{item}</Typography>
                        </Box>
                    ))}
                </Container>
            </>
            }
        </Box>
    );
}
export default Author;