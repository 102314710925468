import {Fab, Typography, useMediaQuery, Zoom} from "@mui/material";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import EventBus from "utils/EventBus";
import {RadioStation} from "utils/RadioStation";

const useStyles = makeStyles(() => ({
    '@keyframes flicker': {
        from: {
            opacity: 1,
        },
        to: {
            opacity: 0.6,
        },
    },
    flicker: {
        animationName: '$flicker',
        animationDuration: '1000ms',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        animationTimingFunction: 'ease-in-out',
    }
}));

const PlayRadio = () => {
    const classes = useStyles();
    const [paused, setPaused] = useState(sessionStorage.getItem("radioStatus") === "paused");
    const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const handleClick = () => {
        setPaused(!paused);
        EventBus.dispatch("playMusic", { status: !paused });
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            EventBus.on("playMusic", (data) => {
                    setPaused(data.status);
                    RadioStation.UpdateRadioStatus(data.status);
                }
            );
        }

        sessionStorage.setItem("radioStatus", paused ? "paused" : "playing")

        return () => {
            isMounted = false;
            EventBus.remove("playMusic");
        };
    }, [paused]);

    return (
        <Zoom
            in={true}
        >
            <Fab
                variant={mobile ? "rounded" : "extended"}
                className={(mobile && !paused) ? classes.flicker : ""}
                color={"primary"}
                sx={{
                    '&:hover': {
                        bgcolor: '#b8812c'
                    },
                    position: 'fixed',
                    margin: 0,
                    top: 'auto',
                    left: 20,
                    bottom: 20,
                    right: 'auto',
                    zIndex: 100
                }}
                aria-label={paused ? 'play' : 'pause'}
                onClick={handleClick}
            >
                {paused ?
                    <>
                        <img alt="Logo" src="/static/images/PlayHeadphonesIcon.svg" height={40} width={40}/>
                        {!mobile && <Typography variant={"coverButton"} color="white" sx={{paddingLeft: 1}}>¡Escúchanos!</Typography>}
                    </>
                 :
                    <>
                        <img alt="Logo" src="/static/images/PauseHeadphonesIcon.svg" height={40} width={40}/>
                        {!mobile && <img alt="gif" src="/static/images/pruebaGif.gif" height={38}/>}
                    </>
                }

            </Fab>
        </Zoom>
    );
}

export default PlayRadio;