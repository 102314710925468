/* eslint-disable no-use-before-define */
import React, {useEffect} from 'react';
import {useLocation, matchPath} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    ListSubheader
} from '@mui/material';

import {makeStyles} from '@mui/styles';

import {
    Home as HomeIcon,
    People as PeopleIcon,
    AccessTime as AccessTimeIcon,
    VolunteerActivism as VolunteerActivismIcon
} from '@mui/icons-material'

import NavItem from './NavItem';

const sections = [
    {
        items: [
            {
                title: 'Inicio',
                icon: HomeIcon,
                href: '/app/inicio'
            },
            {
                title: 'Quiénes somos',
                icon: PeopleIcon,
                href: '/app/quienessomos'
            },
            {
                title: 'Programación',
                icon: AccessTimeIcon,
                href: '/app/programacion'
            },
            {
                title: 'Donaciones',
                icon: VolunteerActivismIcon,
                href: '/app/donaciones'
            }
        ]
    }
];

function renderNavItems({
                            items,
                            pathname,
                            depth = 0
                        }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({acc, item, pathname, depth}),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({
                               acc,
                               pathname,
                               item,
                               depth
                           }) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    }
}));

const NavBar = ({onMobileClose, openMobile}) => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box
                        p={2}
                        display="flex"
                        justifyContent="center"
                    >
                        <RouterLink to="/app/inicio">
                            <img alt={"icono"} src={"/static/images/iconoOP.png"} height={90}/>
                        </RouterLink>
                    </Box>
                </Hidden>
                <Divider/>
                <Box p={2}>
                    {sections.map((section, i) => (
                        <List
                            key={i}
                            subheader={(
                                <ListSubheader
                                    disableGutters
                                    disableSticky
                                >
                                    {section.subheader}
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname
                            })}
                        </List>
                    ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
