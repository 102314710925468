import React from 'react';
import {Box, Container, Divider, Grid, Typography, useMediaQuery} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: 24,
    },
    paragraph: {
        marginBottom: theme.spacing(4),
        textAlign: 'center'
    },
    justAlignText: {
        textAlign: 'center'
    },
    boxInfo: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("md")] : {
            flexDirection: "column"
        },
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    }
}));

const RadioHistory = ({info}) => {
    const classes = useStyles();
    const mobile = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <Box mt={2}>
            <Box className={classes.root}>
                <Typography variant={"titles"} color="text.primary"> {info.name} </Typography>
            </Box>
            <Container
                className={classes.justAlignText}
                sx={{
                    marginY: 6
                }}
            >
                <Typography variant={"sharedBody"} color={"text.secondary"}>{info.intro}</Typography>
            </Container>
            <Box
                p={3}
                className={classes.boxInfo}
                sx={{
                    backgroundColor: 'primary.dark',
                }}
            >
                <Box>
                    <Typography variant="slogan" sx={{color: 'white'}}>
                        C
                    </Typography>
                    <Typography variant="sloganFilled" sx={{color: 'white'}}>
                        omprometidos{mobile && <>&nbsp;</>}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="slogan" sx={{color: 'white'}}>
                        C
                    </Typography>
                    <Typography variant="sloganFilled" sx={{color: 'white'}}>
                        on&nbsp;
                    </Typography>
                    <Typography variant="slogan" sx={{color: 'white'}}>
                        L
                    </Typography>
                    <Typography variant="sloganFilled" sx={{color: 'white'}}>
                        a&nbsp;
                    </Typography>
                    <Typography variant="slogan" sx={{color: 'white'}}>
                        V
                    </Typography>
                    <Typography variant="sloganFilled" sx={{color: 'white'}}>
                        erdad
                    </Typography>
                </Box>              
            </Box>
            <Box className={classes.root} my={4}>
                <Grid
                    container
                    px={3}
                    spacing={3}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {info && info.values.map((value, index) => (
                        <Grid item lg={4} sm={6} xs={12} key={index}>
                            <Box sx={{ display: 'flex', justifyContent: 'center'}} mb={1}>
                                <img alt={"value"+index} height={180} src={value.image}/>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"infoRadio"} color={"text.primary"} sx={{textAlign: "center"}}>{value.title}</Typography>
                            </Box>
                            <Box mt={1} sx={{ display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"radioValues"} color={"text.secondary"} sx={{textAlign: "center"}}>
                                    {value.text}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider/>
            <Container sx={{
                marginY: 5,
            }}>
                {info && info.more.map((paragraph, index) => (
                    <Box key={index} className={info.more.length-1 !== index ? classes.paragraph : classes.justAlignText}>
                        <Typography variant={"sharedBody"} color={"text.secondary"}>{paragraph.text}</Typography>
                    </Box>
                ))}
            </Container>
        </Box>
    );
}


export default RadioHistory;