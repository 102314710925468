import React from 'react';
import {makeStyles} from "@mui/styles";
import {Box, Grow, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    image: {
        width: "100%",
        position: "relative",
    },
    boxContainer: {
        position: "relative",
        textAlign: "center",
        color: "white"
    },
    boxInfo: {
        position: "absolute",
        top: "16%",
        left: "8%",
        [theme.breakpoints.down('md')]: {
            top: "18%",
            left: "5%",
        },
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
    },
    author: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
        },
    }
}));

const AuthorImage = ({img}) => {
    const classes = useStyles();

    return (
        <Box mt={1} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: "stretch"
        }}>
            <Box className={classes.boxContainer}>
                <img id={"authorImage"} alt="Logo" src={img} className={classes.image}/>
                <Grow
                    in={true}
                    {...({timeout: 1000})}
                >
                    <Box className={classes.boxInfo}>
                        <Typography variant={"quote"} color="white">"La esperanza de DIOS</Typography>
                        <Typography variant={"quote"} color="white">nos habla de que SIEMPRE</Typography>
                        <Typography variant={"quote"} color="white">hay un mañana MEJOR"</Typography>
                        <Box className={classes.author}>
                            <Typography variant={"quoteauthor"} color="white">— Julio Pérez —</Typography>
                        </Box>
                    </Box>

                </Grow>
            </Box>
        </Box>
    );
}
export default AuthorImage;