import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    subtitles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(8),
        [theme.breakpoints.up("sm")] : {
            marginBottom: theme.spacing(7),
        },
    },
    boxInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    },
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap' //allows to add grid
    },
    box: {
        position: 'relative',
        width: '16rem',
        height: '16rem',
        [theme.breakpoints.down("md")] : {
            width: '13rem',
            height: '13rem',
        },
        [theme.breakpoints.down("sm")] : {
            width: '10rem',
            height: '10rem',
        },
        boxSizing: 'border-box',
        [theme.breakpoints.down("xl")] : {
            marginTop: '2.6rem',
            marginBottom: '2.6rem'
        }
    },
    imageBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        zIndex: 1,
        transition: 'all 0.5s ease-in-out'
    },
    transformImageBox: {
        transform: "translate(-2.8rem, -2.8rem)",
        [theme.breakpoints.down("md")] : {
            transform: 'translate(0, -3.0rem)'
        }
    },
    image: {
        width: '16rem',
        height: '16rem',
        [theme.breakpoints.down("md")] : {
            width: '13rem',
            height: '13rem',
        },
        [theme.breakpoints.down("sm")] : {
            width: '10rem',
            height: '10rem',
        },
        objectFit: 'cover',
        resize: 'both'
    },
    content: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        transition: '0.5s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        textAlign: 'center'
    },
    transformContent: {
        transform: 'translate(2.8rem, 2.8rem)',
        [theme.breakpoints.down("md")] : {
            transform: 'translate(0, 3.0rem)'
        }
    },
    caption: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const BoxMembers = ({title, members}) => {
    const classes = useStyles();
    const [move, setMove] = useState(false);
    const [moveId, setMoveId] = useState("");

    const handleOnHoverBox = (event, id, show) => {
        if (show) {
            setMove(true);
            setMoveId(id);
        } else {
            setMove(false);
            setMoveId("");
        }
    }

    return (
        <>
            <Box className={classes.subtitles}>
                <Typography variant={"titles"} color="text.primary"> {title} </Typography>
            </Box>
            <Box sx={{boxSizing: 'border-box'}}>
                <Box className={classes.container}>
                    {members.map((member, index) => (
                        <Box
                            key={index}
                            mx={4}
                            className={classes.box}
                            onMouseEnter={(e) => handleOnHoverBox(e, member.id,true)}
                            onMouseLeave={(e) => handleOnHoverBox(e, "", false)}
                        >
                            <Box className={move && member.id === moveId ? clsx(classes.imageBox,classes.transformImageBox) : classes.imageBox}>
                                <img
                                    className={classes.image}
                                    alt={member.name}
                                    src={member.image}
                                />
                                <Box className={classes.caption}>
                                    <Typography variant="memberTitle">
                                        {member.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{border: 3, borderColor: 'primary.dark'}} className={move && member.id === moveId ? clsx(classes.content,classes.transformContent) : classes.content}>
                                <Box mb={1} className={classes.boxInfo}>
                                    <Typography variant="memberRol" color="text.secondary">
                                        {member.rol}
                                    </Typography>
                                    <Typography variant="memberRol" color="text.secondary">
                                        {member.rol2}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    );
}

BoxMembers.propTypes = {
    title: PropTypes.string,
    members: PropTypes.array
};

export default BoxMembers;