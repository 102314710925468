import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {Box, Button, Typography, Zoom} from "@mui/material";
import React, {useEffect, useState, useRef} from "react";
import EventBus from "utils/EventBus";
import { PlayCircle as PlayCircleIcon, PauseCircle as PauseCircleIcon, YouTube} from '@mui/icons-material';
import {RadioStation} from "utils/RadioStation";
import {VolunteerActivism as VolunteerActivismIcon} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    coverContainer: {
        height: '100vh',
        width: '100%'
    },
    video: {
        objectFit: "cover",
        width: "100%",
        height: "100vh",
        position: "absolute",
    },
    boxVideo: {
        color: theme.palette.common.black,
        opacity: 0.8,
    },
    boxContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: 'wrap',
        position: "absolute",
    },
    boxInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
    },
    boxPlayRadio: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingBottom: 40
    },
    iconSize: {
        "& .MuiButton-endIcon": {
            '& > *:first-child': {
                fontSize: 55,
                [theme.breakpoints.down("lg")]: {
                    fontSize: 45,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 35,
                },
            },
        }
    },
    image: {
        width: "20%",
        [theme.breakpoints.down("lg")]: {
            width: "35%",
        },
        [theme.breakpoints.down("md")]: {
            width: "40%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "50%",
        },
    },
}));

const Cover = ({cover}) => {
    const classes = useStyles();
    const [paused, setPaused] = useState(sessionStorage.getItem("radioStatus") === "paused");
    const [showFirstText, setShowFirstText] = useState(false);
    const [showSecText, setShowSecText] = useState(false);
    const [showThirdText, setShowThirdText] = useState(false);
    const [showFourthText, setShowFourthText] = useState(false);
    const videoRef = useRef(null);
    const timeRef = useRef(null);
    const navigate = useNavigate();
  
    const timeouts = () => {
        timeRef.current = setTimeout(() => {
            setShowFirstText(true);
        }, 500);
        timeRef.current = setTimeout(() => {
            setShowFirstText(false);
        }, 4000);
        timeRef.current = setTimeout(() => {
            setShowSecText(true);
        }, 4500);
        timeRef.current = setTimeout(() => {
            setShowSecText(false);
        }, 8000);
        timeRef.current = setTimeout(() => {
            setShowThirdText(true);
        }, 8500);
        timeRef.current = setTimeout(() => {
            setShowThirdText(false);
        }, 12000);
        timeRef.current = setTimeout(() => {
            setShowFourthText(true);
        }, 12500);
        timeRef.current = setTimeout(() => {
            setShowFourthText(false);
        }, 16000);
    }

    const handleOnSeeked = () => {
        const video = videoRef.current;
        if (!video) return;
        timeouts();
        console.log(timeRef.current)
    }

    const handleOnPlay = () => {
        timeouts();
        console.log(timeRef.current)
    }

    const handleClick = () => {
        setPaused(!paused);
        EventBus.dispatch("playMusic", { status: !paused });
    }

    const clickYoutube = (network) => {
        window.open('https://www.youtube.com/@julioperezyoutube', '_blank');
    };

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            EventBus.on("playMusic", (data) => {
                    setPaused(data.status);
                    RadioStation.UpdateRadioStatus(data.status);
                }
            );
        }

        sessionStorage.setItem("radioStatus", paused ? "paused" : "playing")

        return () => {
            isMounted = false;
            EventBus.remove("playMusic");
        };
    }, [paused]);

    return (
        <>
        <Box className={classes.coverContainer}>
            <Box className={classes.boxVideo}>
                <video id={"videoradio"} className={classes.video} src={cover.video} autoPlay playsInline loop muted ref={videoRef} onSeeked={handleOnSeeked} onPlay={handleOnPlay}/>
            </Box>
            <Box className={classes.boxContainer}>
                <Box flexGrow={1}/>
                {showSecText &&
                <Box className={classes.boxInfo}>
                    <Zoom in={showSecText}>
                        <Box>
                            <Typography variant={"coverLineLight"} color="white">O</Typography>
                            <Typography variant={"coverBlack"} color="white">NA </Typography>
                            <Typography variant={"coverLineLight"} color="white">P</Typography>
                            <Typography variant={"coverBlack"} color="white">AU </Typography>
                            <Typography variant={"coverLineLight"} color="white">C</Typography>
                            <Typography variant={"coverBlack"} color="white">ATALUNYA</Typography>
                        </Box>
                    </Zoom>
                    <Zoom in={showSecText}>
                        <Box mb={10}>
                            <Typography variant={"coverLineLight"} color="white">O</Typography>
                            <Typography variant={"coverBlack"} color="white">NDA </Typography>
                            <Typography variant={"coverLineLight"} color="white">P</Typography>
                            <Typography variant={"coverBlack"} color="white">AZ</Typography>
                        </Box>
                    </Zoom>
                    <Zoom in={showSecText}>
                        <Typography variant={"coverSubtitle"} color="white">{cover.slogan}</Typography>
                    </Zoom>
                    <Zoom in={showSecText}>
                        <Typography variant={"coverSubtitle"} color="white">{cover.subtitle}</Typography>
                    </Zoom>
                </Box>
                }
                {showFirstText &&
                <Box className={classes.boxInfo}>
                    <Zoom in={showFirstText}>
                        <Typography variant={"coverBlack"} color="white">COLABORA CON ONA PAU</Typography>
                    </Zoom>
                    <Zoom in={showFirstText}>
                        <Typography variant={"coverSubtitle"} color="white">Ayúdanos a seguir en el aire</Typography>
                    </Zoom>
                    <Zoom in={showFirstText}>
                    <Button
                        variant="contained"
                        size="medium"
                        sx={{
                            m: 1,
                            color: "#FF8984",
                            backgroundColor: "#FFEADF",
                            '&:hover': {
                                color: "#ff9590",
                                backgroundColor: "#dfc6be"
                            },
                        }}
                        startIcon={<VolunteerActivismIcon/>}
                        onClick={() => navigate("/app/donaciones")}
                    >
                        <Typography variant={"topBar"}>
                            Dona
                        </Typography>
                    </Button>
                    </Zoom>
                </Box>
                }
                {showThirdText &&
                <Box className={classes.boxInfo}>
                    <Zoom in={showThirdText}>
                        <Typography variant={"coverBlackH2"} color="white">{cover.book.title}</Typography>
                    </Zoom>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Zoom in={showThirdText}>
                            <img id={"bookImage"} alt="Logo" src={cover.book.image} className={classes.image}/>
                        </Zoom>
                        <Zoom in={showThirdText}>
                            <img id={"bookImageQR"} alt="Logo" src={cover.book.imageQR} className={classes.image}/>
                        </Zoom>
                    </Box>
                    
                    <Zoom in={showThirdText}>
                        <Typography variant={"coverSubtitleH2"} color="white">{cover.book.subtitle}</Typography>
                    </Zoom>
                </Box>
                }
                {showFourthText &&
                <Box className={classes.boxInfo}>
                    <Zoom in={showFourthText}>
                        <Typography variant={"coverSubtitle"} color="white">También puedes conectarte al canal de YouTube de</Typography>
                    </Zoom>
                    <Zoom in={showFourthText}>
                        <Button
                            variant="contained"
                            size="medium"
                            sx={{
                                m: 1,
                                color: "#FF8984",
                                backgroundColor: "#FFEADF",
                                '&:hover': {
                                    color: "#ff9590",
                                    backgroundColor: "#dfc6be"
                                },
                            }}
                            startIcon={<YouTube style={{fill: '#e1251d'}}/>}
                            onClick={() => clickYoutube('Youtube')}
                        >
                            <Typography variant={"topBar"}>
                                Julio Pérez
                            </Typography>
                        </Button>                        
                    </Zoom>
                </Box>
                }
                <Box flexGrow={1}/>
                <Box className={classes.boxPlayRadio}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{'&:hover': {bgcolor: '#b8812c'}}}
                        className={classes.iconSize}
                        endIcon={!paused
                            ? <PauseCircleIcon sx={{color:"white"}}/>
                            : <PlayCircleIcon sx={{color:"white"}}/>
                        }
                        onClick={handleClick}
                    >
                        <Typography variant={"coverButton"} color="white" sx={{paddingLeft: 1}}>{cover.listen}</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>        
        </>
    );
}

Cover.propTypes = {
    cover: PropTypes.object
};

export default Cover;