import React from 'react';
import {makeStyles} from "@mui/styles";
import {Box, Grid, Link, Paper, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
    root: {},
    boxInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        alignItems: "center",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    paperTitle: {
        display: "flex",
        justifyContent: 'center',
        textAlign: "center",
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(2)
}));

const DonationsTemp = () => {
    const classes = useStyles();

    return (
        <>
            <Box>
                <Typography align={"center"} variant={"donacionesContent"} color={"text.secondary"} className={classes.paperTitle}> Ayúdanos a seguir proclamando el Evangelio a través de las Ondas.</Typography>
            </Box>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                mt={1}
                spacing={8}
                px={5}
            >
                <Grid item md={12} xs={12}>
                    <Typography variant={"donacionesTitle"} color={"secondary"} className={classes.paperTitle}>DONAR CON PAYPAL</Typography>
                    <Item elevation={10}>
                        <Box className={classes.boxInfo}>
                            <Typography variant={"donacionesContent"} color={"secondary"} sx={{mb: 2}}>
                                Puedes donar con PayPal o Tarjeta de Crédito
                            </Typography>
                            <form action="https://www.paypal.com/donate" method="post" target="_blank">
                                <input type="hidden" name="hosted_button_id" value="53P8ZMTBG4QMN" />
                                <input type="image" src="https://www.paypalobjects.com/es_ES/ES/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - La forma más rápida y segura de pagar en línea!" alt="Botón Donar con PayPal" />
                                <img alt="" border="0" src="https://www.paypal.com/es_ES/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </Box>
                    </Item>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant={"donacionesTitle"} color={"secondary"} className={classes.paperTitle}> DONAR CON BIZUM </Typography>
                    <Item elevation={10}>
                        <Box className={classes.boxInfo}>
                            <Typography variant={"donacionesContent"} color={"secondary"}>
                                Puedes donar mediante BIZUM
                            </Typography>
                            <Typography variant={"donacionesId"} color={"primary.dark"}>
                                IDENTIFICADOR: 00758
                            </Typography>
                        </Box>
                    </Item>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant={"donacionesTitle"} color={"secondary"} className={classes.paperTitle}> DONAR CON TRANSFERENCIA BANCARIA </Typography>
                    <Item elevation={10}>
                        <Box className={classes.boxInfo}>
                            <Typography variant={"donacionesContent"} color={"secondary"}>
                                También puedes hacer tu donativo por transferencia al número de cuenta del Banco Sabadell
                            </Typography>
                            <Typography variant={"donacionesId"} color={"primary.dark"}>
                                ES39 0081 0173 3800 0129 5031
                            </Typography>
                        </Box>
                    </Item>
                </Grid>
            </Grid>
            <Box px={8} py={6}>
                <Typography variant={"sharedBody"} color={"text.secondary"} sx={{marginBottom: 2, textAlign: 'justify'}}>La ASOCIACION CULTURAL RADIO TELEVISION ONDA PAZ puede emitir un certificado a efectos de la declaración del IRPF. Contacta con nosotros por correo <Link href={"mailto:radio@ondapaz.fm"} target="_blank" rel="noopener noreferrer">radio@ondapaz.fm</Link> proporcionando la siguiente información:</Typography>
                <ul>
                    <Typography variant={"sharedBody"} color={"text.secondary"} sx={{marginBottom: 1}}> <li> Nombre y Apellidos </li> </Typography>
                    <Typography variant={"sharedBody"} color={"text.secondary"} sx={{marginBottom: 1}}> <li> D.N.I. </li> </Typography>
                    <Typography variant={"sharedBody"} color={"text.secondary"} sx={{marginBottom: 1}}> <li> Importe donado </li> </Typography>
                    <Typography variant={"sharedBody"} color={"text.secondary"} sx={{marginBottom: 1}}> <li> Fecha de la donación </li> </Typography>
                </ul>
            </Box>
        </>
    );
}
export default DonationsTemp;