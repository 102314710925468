import React, {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material';
import BasicTable from "./BasicTable";
import {ProgramacionService} from "Services/Server/ProgramacionService";
import axios from "axios";

export default function GuideTable() {
    const [expanded, setExpanded] = useState(false);
    const [guide, setGuide] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getProgramacion = async (source) => {
        const data = await ProgramacionService.GetProgramacion(source);
        setGuide(data);
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        async function fetchData() {
            if(!guide) {
                await getProgramacion(source);
            }
        }
        fetchData();

        return () => {
            source.cancel();
        };
    }, [guide]);

    return (
        <>
            { guide && guide.completeProgramme.map((day, index) => (
                <Accordion key={index} expanded={expanded === day.id} onChange={handleChange(day.id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography color="text.secondary" variant="programDays">
                            {day.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BasicTable timetable={day.timetable}/>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
}