import React from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    SvgIcon,
    Paper
} from '@mui/material';
import {Menu as MenuIcon} from 'react-feather';

const MobileTopBar = ({setMobileNavOpen, isScrolling}) => {
    return (
        <>
            <Paper
                elevation={0}
                sx={{
                    display: {xs: 'block', sm: 'block', md: 'block', lg: 'none'},
                    backgroundColor: 'transparent'
                }}
            >
                    <IconButton
                        sx={{color: "white"}}
                        onClick={() => setMobileNavOpen(true)}
                    >
                        <SvgIcon fontSize="small">
                            <MenuIcon/>
                        </SvgIcon>
                    </IconButton>

            </Paper>
        </>
    );
};

MobileTopBar.propTypes = {
    setMobileNavOpen: PropTypes.func,
    isScrolling: PropTypes.number
};

export default MobileTopBar;