import React, {useEffect, useState} from "react";
import {
    Box,
    Typography,
    Stack,
    IconButton,
    Button,
    Container,
    Grid,
    Link,
    Divider, Grow
} from "@mui/material";
import {Facebook, Instagram, WhatsApp, Call, Email} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {UtilsService} from "Services/Server/UtilsService";
import axios from "axios";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    contactButtons: {
        height: 220,
        width: 220,
        [theme.breakpoints.down("lg")]: {
            height: 200,
            width: 200
        },
        [theme.breakpoints.down("md")]: {
            height: 190,
            width: 190
        },
        [theme.breakpoints.down("sm")]: {
            height: 150,
            width: 150
        },
    },
    contactImg: {
        width: 120,
        [theme.breakpoints.down("lg")]: {
            height: 100,
            width: 100
        },
        [theme.breakpoints.down("md")]: {
            height: 80,
            width: 80
        },
        [theme.breakpoints.down("sm")]: {
            height: 60,
            width: 60
        }
    }
}));

const Footer = () => {
    const classes = useStyles();
    const linkFacebook = "https://es-la.facebook.com/ondapazbcn/";
    const linkInstagram = "https://www.instagram.com/onapaubcn/";
    const linkWhatsApp = "https://wa.me/34651826787";
    const linkCorreo = "mailto:radio@ondapaz.fm";
    const linkLlamadas = "tel:+34932466797";
    const [date, setDate] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [footer, setFooter] = useState("");

    const getFooter = async (source) => {
        const data = await UtilsService.GetFooter(source);
        setFooter(data);
    }

    const getYear = () => setDate(new Date().getFullYear());


    useEffect(() => {
        const source = axios.CancelToken.source();

        async function fetchData() {
            if (!footer) {
                setLoading(true);
                await getFooter(source);
            } else setLoading(false);
        }

        getYear();
        fetchData();

        return () => {
            source.cancel();
        };
    }, [footer]);

    return (
        <>
            {!loading &&
            <Box px={{xs: 3, sm: 10}} py={{xs: 5, sm: 10}} bgcolor="secondary.main" color="primary.light">
                <Container maxWidth="lg">
                    <Grid container sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                    }}>
                        {footer && footer.map((contact, index) => (
                            <Grow
                                key={index}
                                in={true}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(true ? { timeout: 2000*index } : {})}
                            >
                                <Grid item key={index} pb={{xs: 2, md: 2, sm: 2}} md={4} sm={6} xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button
                                        variant="contained"
                                        className={classes.contactButtons}
                                        sx={{
                                            borderRadius: '50%',
                                            bgcolor: 'secondary.light',
                                            '&:hover': {
                                                bgcolor: '#55ad74'
                                            }
                                        }}
                                        onClick={() => {
                                            navigate(
                                                "/app/contactaconnosotros",
                                                {
                                                    state: {
                                                        id: contact.id,
                                                        name: contact.name,
                                                        title: contact.title
                                                    }
                                                }
                                            )
                                        }}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Box>
                                                <img alt="Logo" src={contact.image} className={classes.contactImg}/>
                                            </Box>
                                            <Box width={{lg:140, md: 120, sm: 120}}>
                                                <Typography color={"white"} variant={"contactButtons"}> {contact.description} </Typography>
                                            </Box>
                                        </Box>
                                    </Button>
                                </Grid>
                            </Grow>
                        ))}

                    </Grid>
                </Container>
                <Divider color={'white'} sx={{marginY: 5}}/>
                <Container maxWidth="lg">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={4}>
                            <Box borderBottom={1}>
                                <Typography variant="footerTitles">Nuestras emisoras</Typography>
                            </Box>
                            <Box>
                                <Stack marginTop={1} marginLeft={2} spacing={0.4}>
                                    <Typography variant={"footerContent"}>
                                        93.3 FM - Onda Paz Barcelona
                                    </Typography>
                                    <Typography variant={"footerContent"} sx={{mt: 1}}>
                                        94.9 FM - Ona Pau Tarragona
                                    </Typography>
                                    <Typography variant={"footerContent"} sx={{mt: 3}}>
                                        94.8 FM - Ona Pau Girona
                                    </Typography>
                                    <Typography variant={"footerContent"} sx={{mt: 1}}>
                                        Ona Pau Lleida (Próximamente)
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box borderBottom={1}>
                                <Typography variant="footerTitles">Contacta con nosotros</Typography>
                            </Box>
                            <Stack marginTop={0.5} marginLeft={2}>
                                <Button
                                    variant={"text"}
                                    color={"inherit"}
                                    sx={{
                                        justifyContent: 'left',
                                        '&:hover': {
                                            color: "primary.dark",
                                            background: "none"
                                        },
                                        textTransform: "none"
                                    }}
                                    href={linkCorreo}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    startIcon={<Email/>}
                                >
                                    <Typography variant={"footerContent"}>radio@ondapaz.fm</Typography>
                                </Button>
                                <Button
                                    variant={"text"}
                                    color={"inherit"}
                                    sx={{
                                        justifyContent: 'left',
                                        '&:hover': {
                                            color: "primary.dark",
                                            background: "none"
                                        },
                                    }}
                                    href={linkLlamadas}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    startIcon={<Call/>}
                                >
                                    <Typography variant={"footerContent"}>+34 932 466 797</Typography>
                                </Button>
                                <Button
                                    variant={"text"}
                                    color={"inherit"}
                                    sx={{
                                        justifyContent: 'left',
                                        '&:hover': {
                                            color: "primary.dark",
                                            background: "none"
                                        },
                                    }}
                                    href={linkWhatsApp}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    startIcon={<WhatsApp/>}
                                >
                                    <Typography variant={"footerContent"}>+34 657 999 230</Typography>
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box borderBottom={1}>
                                <Typography variant="footerTitles"> Otros </Typography>
                            </Box>
                            <Box marginTop={1}>
                                <Link onClick={() => navigate("/app/advertencialegal")} color={"inherit"} underline="none" sx={{'&:hover': {color: "primary.dark"}}}>
                                    <Typography variant={"footerContent"}> Advertencia legal </Typography>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{textAlign: 'center'}} pt={{xs: 5, sm: 10}} pb={{xs: 5, sm: 0}}>
                        <Stack direction="row" justifyContent="center" spacing={0.2}>
                            <IconButton color={"inherit"} href={linkFacebook} target="_blank" rel="noopener noreferrer" sx={{'&:hover': {color: "#3b5998"}}}>
                                <Facebook fontSize="medium"/>
                            </IconButton>
                            <IconButton color={"inherit"} href={linkInstagram} target="_blank" rel="noopener noreferrer" sx={{'&:hover': {color: "#dd2a7b"}}}>
                                <Instagram fontSize="medium"/>
                            </IconButton>
                        </Stack>
                        <Typography variant="caption">
                            Copyright © {date} Ona Pau Catalunya. Todos los derechos reservados.
                        </Typography>
                        <br/>
                        <Typography variant="caption">
                            Diseñado y programado por <a href="https://www.linkedin.com/in/erika-salome-morales-mu%C3%B1oz-a953b51b0">Erika Morales</a>
                        </Typography>
                    </Box>
                </Container>
            </Box>
            }
        </>
    );

}

export default Footer;